import DiscordApi from '@core/api/discord'

export default {
  state: {
    profileColors: [],
  },
  getters: {},
  mutations: {
    SET_COLORS(state, profileColors) {
      state.profileColors = profileColors
    },

    EDIT_COLORS(state, value) {
      const editedColorIdx = state.profileColors.findIndex(p => p.id === value.id)

      if (~editedColorIdx) {
        state.profileColors.splice(editedColorIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_COLOR(state, color) {
      const deletedColorIdx = state.profileColors.findIndex(p => p.id === color.id)

      //
      if (~deletedColorIdx) {
        return state.profileColors.splice(deletedColorIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchColors({ commit }, params) {
      return DiscordApi.ProfileData.ProfileColors.fetchColors(params)
        .then(response => {
          commit('SET_COLORS', response.data.items)

          return response
        })
    },

    addColor(ctx, { color }) {
      return DiscordApi.ProfileData.ProfileColors.addColor({ color })
    },

    updateColor(ctx, { color }) {
      return DiscordApi.ProfileData.ProfileColors.updateColor({ color })
    },

    removeColor(ctx, color) {
      return DiscordApi.ProfileData.ProfileColors.removeColor(color)
    },
  },
}
