<template>
  <v-card
    class="drag-and-drop"
    dropzone=""
    role="button"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
  >
    <input
      ref="imageInput"
      class="d-none"
      accept=".jpg,.png,.gif"
      type="file"
      multiple
      @input="onInput"
    />
    <v-layout
      :class="['ma-4',validated?'drag-and-drop__placeholder':'drag-and-drop__error']"
      align-center
      justify-center
      @click="focusInput"
    >
      <v-icon
        :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
        size="60"
      >
        {{ icons.mdiCloudUpload }}
      </v-icon>
      <p>
        Drop your image(s) here, or click to select them.
      </p>
    </v-layout>
    <div
      v-if="allImages.length"
      class="file-preview__container pa-4"
    >
      <v-hover
        v-for="(img, index) in allImages"
        :key="index"
      >
        <template v-slot:default="{ hover }">
          <v-card

            class="file-preview__item"
            :elevation="hover ? 24 : 0"
          >
            <v-img
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :src="img.data"
            >
              <v-card-text>
                <v-icon
                  v-if="img.uploaded"
                  color="success"
                >
                  {{ icons.mdiCloudCheck }}
                </v-icon>
                {{ img.file&&img.file.name }}
              </v-card-text>
            </v-img>
            <v-btn
              v-if="!img.isUrl"
              v-show="hover || img.loading"
              class="file-preview__delete"
              icon
              :loading="img.loading"
              @click="removeFile(img.file.name)"
            >
              <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
            </v-btn>
            <v-btn
              v-if="img.isUrl"
              v-show="hover || img.loading"
              class="file-preview__delete"
              icon
              :loading="img.loading"
              @click="$emit('delete',img.index)"
            >
              <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
            </v-btn>
          </v-card>
        </template>
      </v-hover>
    </div>
  </v-card>
</template>

<script>
import {
  mdiImage, mdiCloseCircle, mdiCloudCheck, mdiCloudUpload,
} from '@mdi/js'
import DiscordApi from '@core/api/discord'
import { urlValidator } from '@core/utils/validation'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => ([]),
    },
    validated: {
      type: Boolean,
      default: true,
    },
    urls: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dragover: false,
      icons: {
        mdiImage,
        mdiCloseCircle,
        mdiCloudUpload,
        mdiCloudCheck,
      },
      urlValidator,
      urlImages: [],
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    allImages() {
      return this.localValue.concat(this.urlImages)
    },
  },
  watch: {
    urls() {
      this.urlImages = []
      if (this.urls && this.urls.length) {
        this.setImageUrls()
      }
    },
  },
  mounted() {
    if (this.urls && this.urls.length) {
      this.setImageUrls()
    }
  },
  methods: {
    imageData(file) {
      return URL.createObjectURL(file)
    },

    async uploadImages() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.localValue.length; i++) {
        const formData = new FormData()
        formData.append('file', this.localValue[i].file)
        this.localValue[i].loading = true
        // eslint-disable-next-line no-await-in-loop
        const { data } = await DiscordApi.ProfileData.Avatar.uploadImage({ formData })
        this.localValue[i].url = data.url
        this.localValue[i].loading = false
        this.localValue[i].uploaded = true
      }

      return this.localValue
    },

    removeFile(fileName) {
      const index = this.localValue.findIndex(
        img => img.file.name === fileName,
      )

      // If file is in uploaded files remove it
      if (index > -1) this.localValue.splice(index, 1)
    },

    focusInput() {
      this.$refs.imageInput.click()
    },

    onInput(e) {
      const files = [...e.target.files].map(file => ({
        file,
        data: URL.createObjectURL(file),
        uploaded: false,
        loading: false,
      }))

      this.localValue.push(...files)
    },

    onDrop(e) {
      this.dragover = false

      // Check if there are already uploaded files
      if (this.localValue.length > 0) this.localValue = []
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        // test
      } else {
        const files = [...e.dataTransfer.files].filter(el => el.type.match(/\/(jpeg|jpg|png|gif)\b/)).map(file => ({
          file,
          data: URL.createObjectURL(file),
          loading: false,
          uploaded: false,
        }))

        this.localValue.push(...files)
      }
    },
    setImageUrls() {
      this.urls.trim().split('\n').forEach((el, index) => {
        if (typeof urlValidator(el) !== 'string') {
          this.urlImages.push({
            data: el, loading: false, uploaded: false, isUrl: true, index,
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.drag-and-drop{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__placeholder {
    display: flex;
    border: 2px dashed var(--v-primary-base);
    flex-direction: column;
  }
  &__error{
    border: 2px solid #F44336;
  }
  .file-preview {
    &__container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      max-height: 300px;
      overflow: auto;
      grid-gap: 16px;
    }

    &__item {
      position: relative;
    }

    &__delete {
      position: absolute;
      top: 10px;
      right: 10px;

    }
  }
}

</style>
