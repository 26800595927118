<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedAvatars.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyAvatars(selectedAvatars)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <TableSearch
        v-model="tableOptions.search"

        hide-filter
      />
      <v-spacer />
      <AddNewAvatar
        @submitted="tryFetchAvatars"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedAvatars"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading avatars ..."
        :server-items-length="total"
        :loading="loading"
        :items="avatars"
        show-expand
        :headers="avatarsHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.value="{ item }">
          <v-skeleton-loader
            :loading="item.loading"
            type="table-cell"
          >
            {{ item.value }}
          </v-skeleton-loader>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="1"></td>
          <td :colspan="headers.length - 1">
            <table class="d-flex flex-wrap justify-space-around align-center my-2">
              <tr
                v-for="(v, index) in item.value"
                :key="index"
              >
                <td class="pa-5">
                  <img
                    width="150"
                    :src="v.url||v"
                  >
                </td>
              </tr>
            </table>
          </td>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openAvatarEditModal(item)"
            @delete="tryDeleteAvatar(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <AvatarEditModal
      ref="editAvatarModal"
      @update="tryUpdateAvatar"
    />

    <ConfirmDialog
      ref="deleteAvatarConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete avatars</v-card-title>
          <v-card-text>
            Do you really want to delete avatars
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import discordStoreModule, { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewAvatar from '@/components/discord/profile-data/avatar/AddNew.vue'
import AvatarEditModal from '@/components/discord/profile-data/avatar/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewAvatar,
    AvatarEditModal,
    ConfirmDialog,
    TableActions,
    TableFooter,
    TableSearch,
  },
  data() {
    return {
      total: 50,
      loading: true,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      search: '',
      selectedAvatars: [],
      pages: [50, 100, 200],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
      },
      avatarsHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          filterable: true,
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      avatars(state) {
        return state[DISCORD_MODULE_NAME]?.avatar.avatars
      },
    }),
    mappedOptions() {
      return this.$route.query
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchAvatars(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(DISCORD_MODULE_NAME)) {
      store.registerModule(DISCORD_MODULE_NAME, discordStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchAvatars(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/fetchAvatars`, payload)
      },
      updateAvatar(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/updateAvatar`, payload)
      },
      removeAvatar(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/removeAvatar`, payload)
      },
    }),

    ...mapMutations({
      commitSetAvatars(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/SET_AVATARS `, payload)
      },
      commitEditAvatar(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/EDIT_AVATAR`, payload)
      },
      commitDeleteAvatar(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/REMOVE_AVATAR`, payload)
      },
    }),

    async tryFetchAvatars(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchAvatars(params)
      this.total = data.total
      this.loading = false
    },

    async tryDeleteAvatar(id) {
      this.$refs.deleteAvatarConfirm.open().then(() => {
        this.commitEditAvatar({ id, loading: true })
        this.removeAvatar({ id }).then(() => {
          this.commitDeleteAvatar({ id })
          this.$message.success('Avatar deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateAvatar(value) {
      this.commitEditAvatar({ ...value, loading: true })

      const { data } = await this.updateAvatar({ avatar: value })

      this.commitEditAvatar(data)
    },

    openAvatarEditModal(avatars) {
      this.$refs.editAvatarModal.open(avatars)
    },
  },
}
</script>
