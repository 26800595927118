import DiscordApi from '@core/api/discord'

export default {
  state: {
    passwords: [],
  },
  getters: {},
  mutations: {
    SET_PASSWORDS(state, passwords) {
      state.passwords = passwords
    },

    EDIT_PASSWORD(state, value) {
      const editedPasswordIdx = state.passwords.findIndex(p => p.id === value.id)

      if (~editedPasswordIdx) {
        state.passwords.splice(editedPasswordIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_PASSWORD(state, password) {
      const deletedPasswordIdx = state.passwords.findIndex(p => p.id === password.id)

      if (~deletedPasswordIdx) {
        return state.passwords.splice(deletedPasswordIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchPasswords({ commit }, params) {
      return DiscordApi.ProfileData.Password.fetchPasswords(params)
        .then(response => {
          commit('SET_PASSWORDS', response.data.items)

          return response
        })
    },

    addPassword(ctx, { password }) {
      return DiscordApi.ProfileData.Password.addPassword({ password })
    },

    updatePassword(ctx, { password }) {
      return DiscordApi.ProfileData.Password.updatePassword({ password })
    },

    removePassword(ctx, password) {
      return DiscordApi.ProfileData.Password.removePassword(password)
    },
  },
}
