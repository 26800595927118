import avatar from './profile-data/avatar'
import bio from './profile-data/bio'
import nickname from './profile-data/nickname'
import password from './profile-data/password'
import profileColor from './profile-data/profile-color'
import account from './account'
import action from './action'
import project from './project'

export const DISCORD_MODULE_NAME = 'app-discord'

export default {
  namespaced: true,
  modules: {
    nickname,
    bio,
    avatar,
    action,
    password,
    account,
    project,
    profileColor,
  },
}
