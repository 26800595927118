<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <v-col
          cols="12"
          lg="12"
        >
          <label for="text">Name</label>
        </v-col>
        <v-col
          cols="12"
          lg="12"
        >
          <v-text-field
            id="text"
            v-model="name"
            type="textarea"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter name"
            :disabled="loading"
            :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="12"
        >
          <label for="stringValues">Image URL</label>
        </v-col>
        <v-col
          cols="12"
          lg="12"
        >
          <v-textarea
            id="stringValues"
            v-model="stringValues"
            type="textarea"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter URLs"
            :disabled="loading"
            :rules="[images && images.length ? true : imageValidation ]"
            @blur="blurEventHandler"
          ></v-textarea>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          lg="12"
        >
          <v-btn
            color="primary"
            :loading="loading"
            @click="submit"
          >
            Create
          </v-btn>
          <v-btn
            type="reset"
            class="mx-2"
            :disabled="loading"
            outlined
            @click="reset"
          >
            Reset
          </v-btn>
        </v-col>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        lg="6"
      >
        <DragAndDrop
          ref="dragAndDrop"
          v-model="images"
          :validated="validated"
          :urls="stringValues"
          @delete="(index)=>urlDelete(index)"
        />
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'

import { required, urlValidator } from '@core/utils/validation'
import DragAndDrop from '@/components/DragAndDrop.vue'

export default {
  components: { DragAndDrop },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      validated: true,
      isEmpty: true,
    }
  },
  watch: {
    images() {
      if (this.images && this.images.length) {
        this.validated = true
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.validated = true
    })
  },
  methods: {
    imageValidation(value) {
      if (value && value.length && value.trim()) {
        this.validated = true
        this.isEmpty = false
        let validationValue = true
        value.trim().split('\n').forEach(el => {
          if (typeof urlValidator(el) === 'string') {
            validationValue = urlValidator(el)
          }
        })

        if (validationValue !== true) {
          return validationValue
        }

        return true
      }
      this.validated = false

      return 'This field is required or drop your images in dropdown'
    },
    blurEventHandler() {
      if (this.isEmpty) {
        this.validated = false
      }
    },
    urlDelete(index) {
      const imageUrls = this.stringValues.trim().split('\n')
      imageUrls.splice(index, 1)
      this.stringValues = imageUrls.join('\n')
    },
  },
  setup(props, { emit, refs }) {
    const form = ref(null)
    const validate = () => form.value.validate()
    const reset = () => {
      form.value.reset()
    }
    const name = ref('')
    const stringValues = ref('')
    const images = ref([])

    const submit = async () => {
      if (validate()) {
        const uploadedImages = await refs.dragAndDrop.uploadImages()
        const data = {
          name: name.value,
          urls: stringValues.value,
          value: uploadedImages.map(({ url }) => ({ url })),
        }
        emit('submit', data)
      }
    }

    return {
      form,
      name,
      images,
      stringValues,
      validate,
      reset,
      required,
      urlValidator,
      submit,
    }
  },
}
</script>
