var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAvatars.length),expression:"selectedAvatars.length"}],staticClass:"mr-6",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.tryDeleteManyAvatars(_vm.selectedAvatars)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))])],1),_c('TableSearch',{attrs:{"hide-filter":""},model:{value:(_vm.tableOptions.search),callback:function ($$v) {_vm.$set(_vm.tableOptions, "search", $$v)},expression:"tableOptions.search"}}),_c('v-spacer'),_c('AddNewAvatar',{on:{"submitted":_vm.tryFetchAvatars}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"item-key":"id","loading-text":"Loading avatars ...","server-items-length":_vm.total,"loading":_vm.loading,"items":_vm.avatars,"show-expand":"","headers":_vm.avatarsHeaders,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.page,"total":_vm.total}},on))]}},{key:"item.value",fn:function(ref){
      var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":"1"}}),_c('td',{attrs:{"colspan":headers.length - 1}},[_c('table',{staticClass:"d-flex flex-wrap justify-space-around align-center my-2"},_vm._l((item.value),function(v,index){return _c('tr',{key:index},[_c('td',{staticClass:"pa-5"},[_c('img',{attrs:{"width":"150","src":v.url||v}})])])}),0)])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openAvatarEditModal(item)},"delete":function($event){return _vm.tryDeleteAvatar(item.id)}}})]}},{key:"footer",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedAvatars),callback:function ($$v) {_vm.selectedAvatars=$$v},expression:"selectedAvatars"}})],1),_c('AvatarEditModal',{ref:"editAvatarModal",on:{"update":_vm.tryUpdateAvatar}}),_c('ConfirmDialog',{ref:"deleteAvatarConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var agree = ref.agree;
      var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete avatars")]),_c('v-card-text',[_vm._v(" Do you really want to delete avatars ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }