import axios from '@axios'

export default {
  ProfileData: {
    Avatar: {
      fetchAvatars: params => axios
        .get('/profile_data/avatar', {
          params,
        }),

      addAvatar: ({ avatar }) => axios
        .post('/profile_data/avatar', avatar),

      updateAvatar: ({ avatar }) => axios
        .patch(`/profile_data/avatar/${avatar.id}`, avatar),

      removeAvatar: avatar => axios
        .delete(`/profile_data/avatar/${avatar.id}`),
      removeMany: ({ avatars, filter }) => axios
        .post('/profile_data/avatar/mass-delete', { avatars, filter }),
      uploadImage: ({ formData }) => axios
        .post('/image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
    },
    Nickname: {
      fetchNicknames: params => axios
        .get('/profile_data/nickname', {
          params,
        }),

      addNickname: ({ nickname }) => axios
        .post('/profile_data/nickname', nickname),

      updateNickname: ({ nickname }) => axios
        .patch(`/profile_data/nickname/${nickname.id}`, nickname),

      removeNickname: nickname => axios
        .delete(`/profile_data/nickname/${nickname.id}`),
      removeMany: ({ nicknames, filter }) => axios
        .post('/profile_data/nickname/mass-delete', { nicknames, filter }),
    },
    Password: {
      fetchPasswords: (params = {}) => axios
        .get('/profile_data/password', {
          params,
        }),

      addPassword: ({ password }) => axios
        .post('/profile_data/password', password),

      updatePassword: ({ password }) => axios
        .patch(`/profile_data/password/${password.id}`, password),

      removePassword: password => axios
        .delete(`/profile_data/password/${password.id}`),
      removeMany: ({ passwords, filter }) => axios
        .post('/profile_data/password/mass-delete', { passwords, filter }),
    },
    Bio: {
      fetchBios: params => axios
        .get('/profile_data/bio', {
          params,
        }),

      addBio: ({ bio }) => axios
        .post('/profile_data/bio', bio),

      updateBio: ({ bio }) => axios
        .patch(`/profile_data/bio/${bio.id}`, bio),

      removeBio: bio => axios
        .delete(`/profile_data/bio/${bio.id}`),
      removeMany: ({ bios, filter }) => axios
        .post('/profile_data/bio/mass-delete', { bios, filter }),
    },
    ProfileColors: {
      fetchColors: params => axios
        .get('/profile_data/profile_color', {
          params,
        }),

      addColor: ({ color }) => axios
        .post('/profile_data/profile_color', color),

      updateColor: ({ color }) => axios
        .patch(`/profile_data/profile_color/${color.id}`, color),

      removeColor: color => axios
        .delete(`/profile_data/profile_color/${color.id}`),
      removeMany: ({ colors, filter }) => axios
        .post('/profile_data/profile_color/mass-delete', { colors, filter }),
    },
  },
  Account: {
    fetchAccounts: params => axios
      .get('/discord-account', {
        params,
      }),

    fetchTags: params => axios.get('/tag', params),

    addAccount: ({ account }) => axios
      .post('/discord-account', account),

    updateAccount: ({ account }) => axios
      .patch(`/discord-account/${account.id}`, account),

    removeAccount: account => axios
      .delete(`/discord-account/${account.id}`),

    removeMany: ({ accounts, filter }) => axios
      .post('/discord-account/mass-delete', { accounts, filter }),

    updateInfo: params => axios
      .post('/discord-account/update_info/', params),

    changeDiscriminator: params => axios
      .post('/discord-account/change_discriminator', params),

    changePassword: params => axios
      .post('/discord-account/change_password', params),

    changeAvatar: params => axios
      .post('/discord-account/change_avatar', params),

    changeProfileColor: params => axios
      .post('/discord-account/change_profile_color', params),

    changeBanner: params => axios
      .post('/discord-account/change_banner', params),

    changeBio: params => axios
      .post('/discord-account/change_bio', params),

    changeNickname: params => axios
      .post('/discord-account/change_nickname', params),

    changeType: params => axios
      .post('/discord-account/mass-change/type', params),

    changeProject: params => axios
      .post('/discord-account/mass-change/project', params),

    changeTag: params => axios
      .post('/discord-account/mass-change/tag', params),

    changeProxy: params => axios
      .post('/discord-account/mass-change/proxy', params),

    // eslint-disable-next-line camelcase
    copyAccount: ({ user_id, id, profile_fields_to_change }) => axios
      .post(`/discord-account/copy_account/${id}`, { user_id, profile_fields_to_change }),

    leaveGuild: params => axios
      .post('/discord-account/leave_guild', params),
  },
  Project: {
    fetchProjects: params => axios
      .get('/project', { params }),

    fetchProject: id => axios
      .get(`/project/${id}`),

    fetchProjectDiscordAccount: (id, params) => axios
      .get(`/project/discord_account?for_project=true&project[]=${id}`, { params }),

    addProject: ({ project }) => axios
      .post('/project', project),

    refreshRoles: (projectId, params) => axios
      .get(`/project/${projectId}/refresh_roles`, { params }),

    checkVerification: (projectId, params) => axios
      .post(`/project/${projectId}/check_verification`, params),

    leaveGuild: (projectId, params) => axios
      .post(`/project/${projectId}/leave_guild`, params),

    joinServer: (projectId, params) => axios
      .post(`/project/${projectId}/join_server`, params),

    buttonByMessage: params => axios
      .post('/button_by_message', params),

    clickButton: (projectId, params) => axios
      .post(`/project/${projectId}/click_button`, params),

    emojiByMessage: params => axios
      .post('/emoji_by_message', params),

    emojiPut: (projectId, params) => axios
      .post(`/project/${projectId}/put_emoji`, params),

    emojiRemove: (projectId, params) => axios
      .post(`/project/${projectId}/remove_emoji`, params),

    updateProject: ({ project }) => axios
      .patch(`/project/${project.id}`, project),

    removeProject: project => axios
      .delete(`/project/${project.id}`),

    removeAccount: (id, params) => axios
      .post(`/project/${id}/remove_account`, params),

    parseAccount: (projectId, params) => axios
      .post(`/project/${projectId}/parse`, params),

    checkGiveaway: projectId => axios
      .get(`/project/${projectId}/check_giveaway`),

    fastJoin: params => axios
      .post('/project/fast_join', params),
  },
  Action: {
    fetchActions: params => axios
      .get('/action', {
        params,
      }),
    fetchAction: (id, params) => axios
      .get(`/action/${id}`, { params }),

    addAction: ({ action }) => axios
      .post('/action', action),

    updateAction: ({ action }) => axios
      .patch(`/action/${action.id}`, action),

    retryAction: (id, params) => axios
      .post(`/action/${id}/retry`, params),

    stopAction: ({ id }) => axios
      .post(`/action/${id}/stop`),

    removeAction: account => axios
      .delete(`/action/${account.id}`),
  },
}
