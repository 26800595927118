import DiscordApi from '@core/api/discord'

export default {
  state: {
    nicknames: [],
  },
  getters: {},
  mutations: {
    SET_NICKNAMES(state, nicknames) {
      state.nicknames = nicknames
    },

    EDIT_NICKNAME(state, value) {
      const editedNicknameIdx = state.nicknames.findIndex(p => p.id === value.id)

      if (~editedNicknameIdx) {
        state.nicknames.splice(editedNicknameIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_NICKNAME(state, nickname) {
      const deletedNicknameIdx = state.nicknames.findIndex(p => p.id === nickname.id)

      //
      if (~deletedNicknameIdx) {
        return state.nicknames.splice(deletedNicknameIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchNicknames({ commit }, params) {
      return DiscordApi.ProfileData.Nickname.fetchNicknames(params)
        .then(response => {
          commit('SET_NICKNAMES', response.data.items)

          return response
        })
    },

    addNickname(ctx, { nickname }) {
      return DiscordApi.ProfileData.Nickname.addNickname({ nickname })
    },

    updateNickname(ctx, { nickname }) {
      return DiscordApi.ProfileData.Nickname.updateNickname({ nickname })
    },

    removeNickname(ctx, nickname) {
      return DiscordApi.ProfileData.Nickname.removeNickname(nickname)
    },
  },
}
