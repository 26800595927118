import DiscordApi from '@core/api/discord'

export default {
  state: {
    bios: [],
  },
  getters: {},
  mutations: {
    SET_BIOS(state, bios) {
      state.bios = bios
    },

    EDIT_BIO(state, value) {
      const editedBioIdx = state.bios.findIndex(p => p.id === value.id)

      if (~editedBioIdx) {
        state.bios.splice(editedBioIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_BIO(state, bio) {
      const deletedBioIdx = state.bios.findIndex(p => p.id === bio.id)

      //
      if (~deletedBioIdx) {
        return state.bios.splice(deletedBioIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchBios({ commit }, params) {
      return DiscordApi.ProfileData.Bio.fetchBios(params)
        .then(response => {
          commit('SET_BIOS', response.data.items)

          return response
        })
    },

    addBio(ctx, { bio }) {
      return DiscordApi.ProfileData.Bio.addBio({ bio })
    },

    updateBio(ctx, { bio }) {
      return DiscordApi.ProfileData.Bio.updateBio({ bio })
    },

    removeBio(ctx, bio) {
      return DiscordApi.ProfileData.Bio.removeBio(bio)
    },
  },
}
