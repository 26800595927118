import DiscordApi from '@core/api/discord'

export default {
  state: {
    avatars: [],
  },
  getters: {},
  mutations: {
    SET_AVATARS(state, avatars) {
      state.avatars = avatars
    },

    EDIT_AVATAR(state, value) {
      const editedAvatarIdx = state.avatars.findIndex(p => p.id === value.id)

      if (~editedAvatarIdx) {
        state.avatars.splice(editedAvatarIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_AVATAR(state, avatar) {
      const deletedAvatarIdx = state.avatars.findIndex(p => p.id === avatar.id)

      //
      if (~deletedAvatarIdx) {
        return state.avatars.splice(deletedAvatarIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchAvatars({ commit }, params) {
      return DiscordApi.ProfileData.Avatar.fetchAvatars(params)
        .then(response => {
          commit('SET_AVATARS', response.data.items)

          return response
        })
    },

    addAvatar(ctx, { avatar }) {
      return DiscordApi.ProfileData.Avatar.addAvatar({ avatar })
    },

    updateAvatar(ctx, { avatar }) {
      return DiscordApi.ProfileData.Avatar.updateAvatar({ avatar })
    },

    removeAvatar(ctx, avatar) {
      return DiscordApi.ProfileData.Avatar.removeAvatar(avatar)
    },
  },
}
