import DiscordApi from '@core/api/discord'

export default {
  state: {
    actions: [],
  },
  getters: {},
  mutations: {
    SET_ACTIONS(state, actions) {
      state.actions = actions
    },

    EDIT_ACTION(state, value) {
      const editedActionIdx = state.actions.findIndex(p => p.id === value.id)

      if (~editedActionIdx) {
        state.actions.splice(editedActionIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_ACTION(state, action) {
      const deletedActionIdx = state.actions.findIndex(p => p.id === action.id)

      //
      if (~deletedActionIdx) {
        return state.actions.splice(deletedActionIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchActions({ commit }, options) {
      return DiscordApi.Action.fetchActions(options)
        .then(response => {
          commit('SET_ACTIONS', response.data.items)

          return response
        })
    },

    addAction(ctx, { action }) {
      return DiscordApi.Action.addAction({ action })
    },

    updateAction(ctx, { action }) {
      return DiscordApi.Action.updateAction({ action })
    },

    removeAction(ctx, action) {
      return DiscordApi.Action.removeAction(action)
    },
  },
}
