import DiscordApi from '@core/api/discord'

export default {
  state: {
    projects: [],
  },
  getters: {},
  mutations: {
    SET_PROJECTS(state, projects) {
      state.projects = projects
    },

    EDIT_PROJECT(state, value) {
      const editedProjectIdx = state.projects.findIndex(p => p.id === value.id)

      if (~editedProjectIdx) {
        state.projects.splice(editedProjectIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_PROJECT(state, project) {
      const deletedProjectIdx = state.projects.findIndex(p => p.id === project.id)

      //
      if (~deletedProjectIdx) {
        return state.projects.splice(deletedProjectIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchProjects({ commit }, params) {
      return DiscordApi.Project.fetchProjects(params)
        .then(response => {
          commit('SET_PROJECTS', response.data.items)

          return response
        })
    },

    addProject(ctx, { project }) {
      return DiscordApi.Project.addProject({ project })
    },

    updateProject(ctx, { project }) {
      return DiscordApi.Project.updateProject({ project })
    },

    removeProject(ctx, project) {
      return DiscordApi.Project.removeProject(project)
    },
  },
}
